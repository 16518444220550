
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
  computed,
} from "vue";
import { getMemberList, getMemberInfo } from "@/api/index.js";
import Sidebar from "@/components/Sidebar/index.vue";
import triangleIcon from "assets/images/triangle_icon.png";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  setup() {
    interface props {
      level?: any;
      articleId?: any;
      memberList?: Array<any>;
      total?: any;
      page?: any;
      limit?: any;
      isShow?: boolean;
      loading?: boolean;
      currentItem?: {
        logo?: string;
        name?: string;
        time?: string;
        hits?: any;
        info?: string;
        business?: string;
        team_style?: string;
        honors_qualifications?: string;
      };
    }
    let state: props = reactive({
      level: "3",
      articleId: "",
      memberList: [],
      total: 0,
      page: 1,
      limit: 10,
      isShow: false,
      loading: true,
      currentItem: {
        logo: "",
        name: "",
        time: "",
        hits: 0,
        info: "",
        business: "",
        team_style: "",
        honors_qualifications: "",
      },
    });
    const memberShipList = [
      { name: "常务理事", level: "3" },
      { name: "理事单位", level: "2" },
      { name: "会员单位", level: "1" },
    ];

    const activeIndex = computed(() => {
      let index = 0;
      memberShipList.map((r, i) => {
        if (r.level == state.level) {
          index = i;
        }
      });
      return index;
    });

    const $route = useRoute();
    const $router = useRouter();
    onMounted(() => {
      if ($route.query.level) {
        state.level = $route.query.level;
      }
    });

    const sidebarclick = (item: any) => {
      state.level = item.level;
      state.isShow = false;
    };

    const memberListFn = async (level: any) => {
      const params = {
        level,
        keyword: "",
        page: state.page,
        limit: state.limit,
      };
      return await getMemberList(params).then((res: any) => {
        if (res.code == "0") {
          return res;
        }
      });
    };

    const init = () => {
      memberListFn(state.level).then((res) => {
        state.memberList = res.data || [];
        state.total = Number(res.count);
      });
    };

    const currentChange = (page: any) => {
      state.page = page;
      init();
    };

    init(); // 获取数据

    watch(
      () => state.level,
      (level, prevLevel) => {
        if (level != prevLevel) {
          init();
        }
      }
    );

    const MemberInfo = (id: any) => {
      getMemberInfo({ id }).then((res: any) => {
        state.currentItem = res.data;
        state.loading = false;
      });
    };

    const openDetails = (item: any) => {
      // state.isShow = true;
      // state.currentItem = item;
      // $router.push({
      //   query: ($route.query, { level: state.level, articleId: item.id }),
      //   params: { item: JSON.stringify(item) },
      // });
      const to = $router.resolve({
        path: $route.path,
        query:
          ($route.query,
          {
            level: state.level,
            articleId: item.id,
          }),
      });
      // localStorage.setItem("currentItem", JSON.stringify(item));
      window.open(to.href, "_blank");
    };

    watch(
      () => $route.query,
      (newQuery, oldQuery) => {
        if (newQuery?.level !== oldQuery?.level) {
          if ($route.query.level) {
            state.level = $route.query.level;
          }
          if ($route.query.articleId) {
            state.articleId = $route.query.articleId;
            state.isShow = true;
            MemberInfo(state.articleId);
            state.loading = true;
            // state.currentItem = JSON.parse(
            //   localStorage.getItem("currentItem") || "null"
            // );
            // openDetails({ id: state.articleId });
          }
        }
      },
      { immediate: true }
    );

    return {
      ...toRefs(state),
      triangleIcon,
      activeIndex,
      sidebarclick,
      memberShipList,
      currentChange,
      openDetails,
    };
  },
  components: { Sidebar },
});
